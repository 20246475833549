<template>
  <v-list-item
    dense
    @click="$emit('click')"
  >
    <v-list-item-title>
      <v-icon
        :color="color"
        size="18"
        class="me-2"
      >
        {{ icon }}
      </v-icon>
      <span>{{ label }}</span>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
}
</script>

<style scoped>

</style>
